import { Lab65, Rgb } from 'culori';

import { CLIENTAPI } from './core';

export type Pallete = {
  cold: string[];
  neutral: string[];
  warm: string[];
};

export type Formulation = { [materialId: string]: string };
export type Prediction = {
  LAB?: Lab65;
  RGB: Rgb;
  HEX: string;
  formulation: Formulation;
  dE2000: number | null;
};

type ResolveResponse = {
  vendor: string;
  product: string;
  predictions: Prediction[];
};

const Client = new CLIENTAPI({ baseURL: (process.env.REACT_APP_API_HOST as string) || '' });

const getLandMarks = Client.POST<
  { image: Blob },
  never,
  never,
  { bottomLip: { x: number; y: number }[]; topLip: { x: number; y: number }[] }
>({
  endpoint: [Client.routes.public, 'recommendations', 'detection', 'lips'].join('/'),
  transformRequest: [
    function (body) {
      const newBody = new FormData();
      newBody.append('file', body.image, body.image.name);
      return newBody;
    },
  ],
});

const getBaseColors = Client.GET<{ product: string }, never, { id: string; shade: string }[]>({
  endpoint: [Client.routes.algorithm, 'recommendation'].join('/'),
});

const getPallete = Client.POST<
  { shadeId: string; tintStep?: number; tempStep?: number },
  { product: string },
  never,
  Pallete
>({
  endpoint: [Client.routes.algorithm, 'recommendation', 'pallete', 'hsl'].join('/'),
});

const getFormulation = Client.POST<string[], { product: string, weight: string, variant?: string }, never, ResolveResponse & { targets: Rgb[] }>({
  endpoint: [Client.routes.algorithm, 'resolve', 'formulation'].join('/'),
});

const createProduct = Client.POST<{
  shopifyId?: string;
  metadata: {
    name: string;
    email: string;
    orderDate: string;
  };
  content: {
    type: 'nailgel' | 'nailgel-sample' | 'lipstick';
    derivedFrom: {
      expectedShade: string;
      isSample?: string;
    };
  }[];
}>({
  endpoint: [Client.routes.register, 'orders'],
});

const setLocation = Client.POST<never, never, never, { locationName: string }>({
  endpoint: [Client.routes.auth, 'user', 'settings', 'location'].join('/'),
});

const setCompany = Client.POST({
  endpoint: [Client.routes.auth, 'user', 'settings', 'company'].join('/'),
});

export { Client, getLandMarks, getBaseColors, getPallete, getFormulation, createProduct, setLocation, setCompany };
