import React from 'react';
import { VIEWS, GoToViewFunc, DependSession } from '..';

import { useSession } from '@services/session';
import * as API from '@utils/api';

import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, ButtonGroup, Flex, Grid, GridItem, Heading, Spacer, Text } from '@chakra-ui/react';
import { Swatch } from '@components';

export default function ConfirmView(props: { goToView: GoToViewFunc; shade: string }) {
  const { session, updateSession } = useSession<DependSession>();

  const [isLoading, setLoading] = React.useState(false);
  const [formulation, setFormulation] = React.useState<API.Prediction | null>(null);
  const [comparisonSelected, setComparisonSelected] = React.useState(false);

  const isSkipped = Boolean(session.get('skip'));

  const formulate = React.useCallback(() => {
    setLoading(true);
    API.getFormulation({
      body: [props.shade],
      query: { 
        product: 'nailgel', 
        weight: "4.000",
        variant: "full-coverage"
      },
    })
      .then((response) => {
        setFormulation(response.data.predictions[0]);
        setComparisonSelected(true);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.shade]);

  React.useEffect(() => {
    if(formulation){
      if(comparisonSelected) updateSession({ cc: formulation.HEX, });
      else updateSession({ cc: null, })
    }
  }, [comparisonSelected, formulation, updateSession]);

  return (
    <>
      <GridItem area='body' textAlign='center'>
        <Grid
          templateAreas={`"header" "picker"`}
          templateColumns={'100%'}
          templateRows={'max-content 1fr'}
          gap='0 5vw'
          height='100%'
          flex='1'
        >
          <GridItem p={3} area='header' alignSelf={'stretch'}>
            <Heading as='h1'>Confirm your shade</Heading>
            <Text mt={3}>Are you sure about your shade? Let's formulate it!</Text>
          </GridItem>
          <GridItem p={3} area='picker' alignSelf={'center'}>
            {formulation ? (
                <>
                  <Alert
                    status={(formulation.dE2000 && formulation.dE2000 < 3) ? 'success' : 'warning'}
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height={'100%'}
                    backgroundColor={'white'}
                    borderRadius={10}
                    p={7}
                    shadow='rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                  >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={4} fontSize='2xl'>
                      {formulation.dE2000 && formulation.dE2000 < 3 
                        ? `Your shade has been formulated!`
                        : `The formulated shade looks a bit off...`
                      }
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                      The formulation will create the shade ({formulation.HEX}) shown below on the right.
                      Click on confirm below to 
                    </AlertDescription>
                    <Flex gap='0px 40px' mt={10}>
                      <Flex
                        flexDir='column'
                        onClick={() => {
                          setComparisonSelected(false);
                        }}
                      >
                        <Text mb={6}>
                          <b>Expected shade</b>
                        </Text>
                        <Swatch shade={props.shade} isChecked={comparisonSelected === false} width='140px' />
                      </Flex>
                      <Flex
                        flexDir='column'
                        onClick={() => {
                          setComparisonSelected(true);
                        }}
                      >
                        <Text mb={6}>
                          <b>Formulated shade</b>
                        </Text>
                        <Swatch shade={formulation.HEX} isChecked={comparisonSelected === true} width='140px' />
                      </Flex>
                    </Flex>
                  </Alert>
                </>
              ) : (
                <Alert
                    status={'info'}
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height={'100%'}
                    backgroundColor={'white'}
                    borderRadius={10}
                    p={7}
                    shadow='rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px'
                  >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={4} fontSize='2xl'>
                      Let's formulate your shade!
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                      Click on the button below to let our AI formulate your shade.
                    </AlertDescription>
                    <Button mt={10} colorScheme='dependOpposite' size='lg' isLoading={isLoading} isDisabled={isLoading} onClick={formulate}>
                      Formulate shade
                    </Button>
                  </Alert>
              )}
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem area='nav' textAlign='center'>
        <ButtonGroup p={3} spacing={4}>
          <Button
            size='lg'
            variant={'outline'}
            background={'whiteAlpha.600'}
            onClick={props.goToView.bind(null, isSkipped ? VIEWS.USE_COLOR_CODE : VIEWS.CHOOSE_SHADE)}
          >
            Back
          </Button>
          <Spacer />
          <Button
            size='lg'
            colorScheme='depend'
            isDisabled={formulation === null}
            onClick={props.goToView.bind(null, VIEWS.END)}
          >
            Confirm
          </Button>
        </ButtonGroup>
      </GridItem>
    </>
  );
}
